const pageOneInfo = {
  name: '李世锋',
  title1: '中电数据服务有限公司董事长',
  title2: '中电数融投资管理（杭州）有限公司董事长',
  title_small: '健康医疗大数据是国家重要的基础性战略资源。中电药明承载着未来向医疗大数据生态链延展的重要战略使命。',
  title_normal:
    '<div> 健康医疗大数据是国家重要的基础性战略资源。<span style="color:#004EA2">中电药明</span>承载着未来向医疗大数据生态链延展的重要战略使命。</div>',
  content_small_part:
    '<div>中电药明的成立是中电数据在健康医疗大数据分析领域的重要布局。健康医疗大数据是国家重要的基础性战略资源，大数据的应用发展将......<span style="color:#FFC769">展开全文</span></div>',
  content_small_all:
    '中电药明的成立是中电数据在健康医疗大数据分析领域的重要布局。健康医疗大数据是国家重要的基础性战略资源，大数据的应用发展将带来健康医疗模式的深刻变化。药明康德是全球生物医药领域领先的开放式能力与技术平台，我们非常看好与药明康德的这次合作。未来，我们将综合运用大数据、云计算、人工智能等技术，实现医院、保险、临床、流行病学和处方数据与研发资源的深度融合。全面为在中国的国际和本土合作伙伴提供核心数据解决方案，切实实现中电数据让人们更健康、更幸福的美好愿景。',
  content_normal:
    '<div><div>中电药明的成立是中电数据在健康医疗大数据分析领域的重要布局。健康医疗大数据是国家重要的基础性战略资源，大数据的应用发展将带来健康医疗模式的深刻变化。药明康德是全球生物医药领域领先的开放式能力与技术平台，我们非常看好与药明康德的这次合作。</div><div style="padding:8px 0 0 0">未来，我们将综合运用大数据、云计算、人工智能等技术，实现医院、保险、临床、流行病学和处方数据与研发资源的深度融合。全面为在中国的国际和本土合作伙伴提供核心数据解决方案，切实实现中电数据让人们更健康、更幸福的美好愿景。</div></div>',
};
const pageTwoInfo = {
  name: '李革',
  title1: '药明康德创始人',
  title2: '董事长兼首席执行官',
  title_small:
    '信息技术与生物技术的融合、共享和开放应用成为我国社会进步和健康医疗产业发展的主旋律。中电药明为行业变革与创新提供了更前沿的探索思路。',
  title_normal:
    '<div>信息技术与生物技术的融合、共享和开放应用成为我国社会进步和健康医疗产业发展的主旋律。<span style="color:#004EA2">中电药明</span>为行业变革与创新提供了更前沿的探索思路。</div>',
  content_small_part:
    '<div>与中电数据合作成立中电药明是药明康德深耕医疗大数据产业的重要举措，将有助于我们高效整合诊疗数据与医药研发资源，为大健康生......<span style="color:#FFC769">展开全文</span></div>',
  content_small_all:
    '与中电数据合作成立中电药明是药明康德深耕医疗大数据产业的重要举措，将有助于我们高效整合诊疗数据与医药研发资源，为大健康生态圈提供更多、更有效的解决方案。我们希望通过本次合作，挖掘更精准的病患需求和更具洞察力的医疗保健数据，为更多医生、科学家和医疗健康公司赋能，让医生把病人治得更好，让病人得到更好的治疗，早日实现‘让天下没有难做的药，难治的病’的梦想。',
  content_normal:
    '<div><div>与中电数据合作成立中电药明是药明康德深耕医疗大数据产业的重要举措，将有助于我们高效整合诊疗数据与医药研发资源，为大健康生态圈提供更多、更有效的解决方案。</div><div style="padding:8px 0 0 0">我们希望通过本次合作，挖掘更精准的病患需求和更具洞察力的医疗保健数据，为更多医生、科学家和医疗健康公司赋能，让医生把病人治得更好，让病人得到更好的治疗，早日实现‘让天下没有难做的药，难治的病’的梦想。</div></div>',
};
let page = { pageOneInfo, pageTwoInfo };
export default page;
