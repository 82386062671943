import React, { Component } from 'react';
import styles from './index.module.less';
import Header from './../../components/Header/';
import Footer from './../../component/Footer/';
// import ProImg1 from '../../images/pro_1.png';
// import ProImg2 from '../../images/pro_2.png';
// import ProImg3 from '../../images/pro_3.png';
import PhoneMenu from '../../components/PhoneMenu';

export class ProductService extends Component {
  constructor(props) {
    super(props);
    this.state = { showContactUsModal: false, currentPage: 'service', deviceType: '' };
  }
  // 初始化
  componentDidMount() {
    console.log('组件加载完成');
    // document.getElementById('root').scrollTop({ top: 0, behavior: 'smooth' });
    // document.body.scrollTop = 0;

    console.log(window.pageYOffset, 'pageYOffset');
    window.scroll(0, 0);
    this.setState({ deviceType: this.getClientWidth() });
  }
  // --------------------------------------------- 方法 ------------------------------------------------
  // .获得屏幕宽度类型
  getClientWidth() {
    let clientWidth = document.body.clientWidth;
    if (clientWidth > 0 && clientWidth <= 1024) return 'phone';
    else if (clientWidth >= 1025 && clientWidth <= 1440) return 'pad';
    else if (clientWidth >= 1441) return 'pc';
  }
  clickHeaderIcon = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 760) {
      console.log('小于');
      this.setState({
        showContactUsModal: true,
      });
    } else {
      this.props.history.push(`/contactus`);
    }
  };
  closePhoneMenuModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };
  jumpToContactUsPage = () => {
    console.log('jumpToContactUsPage');
    this.props.history.push(`/contactus`);
  };
  // 产品服务
  jumpToServicePage = () => {
    console.log('jumpToServicePage');
    this.setState({
      showContactUsModal: false,
    });
  };

  clickHeaderService = () => {
    this.setState(
      {
        currentPage: 'service',
      },
      () => {
        this.props.history.push(`/service`);
      },
    );
  };
  clickHeaderJob = () => {
    const w = window.open('about:blank');
    w.location.href = `https://app.mokahr.com/social-recruitment/cwdata/44640`;
  };

  clickFooterIndex = () => {
    this.props.history.push(`/`);
  };
  touchToUs = () => {
    this.props.history.push(`/contactus`);
  };
  clickGoBack = () => {
    console.log(111);
    this.props.history.push(`/`);
  };

  jumpToJobPage = () => {
    window.location.href = `https://app.mokahr.com/social-recruitment/cwdata/44640`;
  };
  render() {
    const { showContactUsModal, deviceType } = this.state;
    return (
      <div>
        <div className={styles.headBox}>
          <Header
            clickHeaderIcon={this.clickHeaderIcon}
            clickHeaderService={this.clickHeaderService}
            clickGoBack={this.clickGoBack}
            clickHeaderJob={this.clickHeaderJob}
            currentPage={this.state.currentPage}
          />
          {showContactUsModal && (
            <PhoneMenu
              closePhoneMenuModal={this.closePhoneMenuModal}
              jumpToContactUsPage={this.jumpToContactUsPage}
              jumpToServicePage={this.jumpToServicePage}
              jumpToJobPage={this.jumpToJobPage}
            />
          )}
        </div>
        <div className={styles.smallBox_center} />
        <div className={styles.title}>
          <div className={styles.text}>产品矩阵与服务支持</div>
        </div>

        {/* <div className={styles.middle_banner}>
          <div className={styles.currently_selected}>医疗药品</div>
          <div>医疗器械</div>
        </div> */}
        <div className={styles.contentColor}>
          <div className={styles.middle_content}>
            <div className={styles.middle_content_text1}>全面支撑药物临床开发</div>

            <div className={styles.middle_content_text2}>
              依托中电数据多地区、全系统、全生命周期的真实世界临床数据的积累和实时（准实时）数据采集、汇聚、治理能力；受益于药明康德成熟的临床研究项目经验；基于中电药明生命科学数据专有云平台特有的AI应用；我们期望应用海量的真实世界临床数据在药物研发立项阶段、临床I-III阶段、上市临床IV阶段、上市后循证开发阶段，为药物创新合作伙伴提供全面的临床开发支撑。包括但不限于基于真实世界数据的研发方向论证、极速受试者招募、定向临床数据积累、药物临床证据、临床价值再开发等方向。
            </div>

            {/* <div className={styles.middle_content_threeBox}>
              <div className={styles.middle_content_threeBox_item}>
                <img src={ProImg1} alt="" className={styles.middle_content_threeBox_item_img} />
                <div className={styles.middle_content_threeBox_item_text} style={{ color: '#004ea2' }}>
                  面向临床 I 期 – III 期的极速患者招募
                </div>
              </div>
              <div className={styles.middle_content_threeBox_item}>
                <img src={ProImg2} alt="" className={styles.middle_content_threeBox_item_img} />
                <div className={styles.middle_content_threeBox_item_text} style={{ color: '#1D8F42' }}>
                  面向创新品种上市的定向临床数据库建设及临床 IV 期达成
                </div>
              </div>
              <div className={styles.middle_content_threeBox_item}>
                <img src={ProImg3} alt="" className={styles.middle_content_threeBox_item_img} />
                <div className={styles.middle_content_threeBox_item_text} style={{ color: '#E59615' }}>
                  面向产品全生命周期的循证研究
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className={styles.tip}>
          <div className={styles.tip_one}>获取更多产品相关信息</div>
          <div className={styles.tip_two} onClick={this.touchToUs.bind(this)}>
            和我们聊一聊
          </div>
        </div>

        {deviceType === 'pc' ? (
          <Footer
            clickFooterIndex={this.clickFooterIndex}
            clickHeaderService={this.clickHeaderService}
            clickHeaderJob={this.clickHeaderJob}
          />
        ) : (
          <Footer
            clickFooterIndex={this.clickFooterIndex}
            clickHeaderService={this.clickHeaderService}
            clickHeaderJob={this.jumpToJobPage}
          />
        )}
      </div>
    );
  }
}

export default ProductService;
