import React, { Component } from 'react';
import styles from './index.module.less';
import Footer from '../../component/Footer/index';
import Header from '../../components/Header';
import PhoneMenu from '../../components/PhoneMenu';
import aboutLogo from '../../images/about_title.png';
import EchartMapBars from '../../components/EChartMapBars';

export class LearnMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContactUsModal: false,
      placeIndex: '1',
      deviceType: '',
    };
  }
  // 初始化
  componentDidMount() {
    window.scroll(0, 0);
    // 获得屏幕宽度类型
    this.setState({ deviceType: this.getClientWidth() });
  }

  // --------------------------------------------- 方法 ------------------------------------------------
  // .获得屏幕宽度类型
  getClientWidth() {
    let clientWidth = document.body.clientWidth;
    if (clientWidth > 0 && clientWidth <= 1024) return 'phone';
    else if (clientWidth >= 1025 && clientWidth <= 1440) return 'pad';
    else if (clientWidth >= 1441) return 'pc';
  }

  touchToUs() {
    this.props.history.push(`/contactus`);
  }
  clickHeaderIcon = () => {
    let innerWidth = window.innerWidth;
    if (innerWidth < 760) {
      console.log('小于');
      this.setState({
        showContactUsModal: true,
      });
    } else {
      this.props.history.push(`/contactus`);
    }
  };
  closePhoneMenuModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };
  jumpToContactUsPage = () => {
    console.log('jumpToContactUsPage');
    this.props.history.push(`/contactus`);
  };
  // 产品服务
  jumpToServicePage = () => {
    console.log('jumpToServicePage');
    // this.setState({
    //   showContactUsModal: false,
    // });
    this.props.history.push(`/service`);
  };
  clickHeaderService = () => {
    this.props.history.push(`/service`);
  };
  clickFooterIndex = () => {
    this.props.history.push(`/`);
  };
  clickGoBack = () => {
    this.props.history.push(`/`);
  };
  closePop = (e) => {
    // alert(11);

    this.setState({
      placeIndex: '',
    });
  };

  clickHeaderJob = () => {
    const w = window.open('about:blank');
    w.location.href = `https://app.mokahr.com/social-recruitment/cwdata/44640`;
  };
  jumpToJobPage = () => {
    window.location.href = `https://app.mokahr.com/social-recruitment/cwdata/44640`;
  };
  render() {
    const { showContactUsModal, deviceType } = this.state;

    return (
      <div style={{ backgroundColor: '#fff' }}>
        <div className={styles.headBox}>
          <Header
            clickHeaderIcon={this.clickHeaderIcon}
            clickHeaderService={this.clickHeaderService}
            clickGoBack={this.clickGoBack}
            clickHeaderJob={this.clickHeaderJob}
          />
          {showContactUsModal && (
            <PhoneMenu
              closePhoneMenuModal={this.closePhoneMenuModal}
              jumpToContactUsPage={this.jumpToContactUsPage}
              jumpToServicePage={this.jumpToServicePage}
              jumpToJobPage={this.jumpToJobPage}
            />
          )}
        </div>

        <div className={styles.title}>
          <div>
            <img src={aboutLogo} alt="" className={styles.text} />
          </div>
          <div className={styles.text1}>
            中电数据服务有限公司（简称中电数据）成立于2014年10月，是在国家卫健委指导下，由中国电子牵头，联合国投集团、国调基金、腾讯等机构设立的国家健康医疗大数据产业发展集团公司，提供健康医疗大数据整合、管理及运营服务。中电数据是规划、建设和运营国家健康医疗大数据试点工程的主体单位，形成了健康医疗大数据安全规范服务的系统能力和可持续应用发展的运营模式，可快速部署支撑数据应用服务的技术体系、制度体系、安全体系、生态体系和创新体系等，是“数字中国”“健康中国”战略的积极实践者。
          </div>
        </div>
        <div className={styles.center_text_1}>中电数据已获得多地市政府的医疗大数据授权，并且持续扩展中</div>
        <div className={styles.center_text_2}>中电药明拥有中电数据平台长期稳定的独占访问权限</div>
        <div onClick={this.closePop.bind(this)}>
          <div className={styles.map}>
            <EchartMapBars />
          </div>
        </div>

        <div className={styles.tip}>
          <div className={styles.tip_one}>选择安全、值得信赖的健康医疗大数据。</div>
          <div className={styles.tip_two} onClick={this.touchToUs.bind(this)}>
            和我们聊一聊
          </div>
        </div>

        {deviceType === 'pc' ? (
          <Footer
            clickFooterIndex={this.clickFooterIndex}
            clickHeaderService={this.clickHeaderService}
            clickHeaderJob={this.clickHeaderJob}
          />
        ) : (
          <Footer
            clickFooterIndex={this.clickFooterIndex}
            clickHeaderService={this.clickHeaderService}
            clickHeaderJob={this.jumpToJobPage}
          />
        )}
      </div>
    );
  }
}

export default LearnMore;
