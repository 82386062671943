import React, { Component } from 'react';
import styles from './index.module.less';
import bigPic1 from '../../../../images/big_1.png';
import bigPic2 from '../../../../images/big_2.png';
import bigPic3 from '../../../../images/big_3.png';
import icon1 from '../../../../images/icon_1.png';
import icon2 from '../../../../images/icon_2.png';
import icon3 from '../../../../images/icon_3.png';
import icon4 from '../../../../images/icon_4.png';
import icon5 from '../../../../images/icon_5.png';
import icon6 from '../../../../images/icon_6.png';
import icon7 from '../../../../images/icon_7.png';
import icon8 from '../../../../images/icon_8.png';
import icon9 from '../../../../images/icon_9.png';
import icon10 from '../../../../images/icon_10.png';
import icon11 from '../../../../images/icon_11.png';
import icon12 from '../../../../images/icon_12.png';
import item11 from '../../../../images/environment/item1_1.png';
import item12 from '../../../../images/environment/item1_2.png';
import item13 from '../../../../images/environment/item1_3.png';
import item14 from '../../../../images/environment/item1_4.png';
import item15 from '../../../../images/environment/item1_5.png';
import item16 from '../../../../images/environment/item1_6.png';

import item21 from '../../../../images/environment/item2_1.png';
import item22 from '../../../../images/environment/item2_2.png';
import item23 from '../../../../images/environment/item2_3.png';
import item24 from '../../../../images/environment/item2_4.png';
import item25 from '../../../../images/environment/item2_5.png';

import item31 from '../../../../images/environment/item3_1.png';
import item32 from '../../../../images/environment/item3_2.png';
import item33 from '../../../../images/environment/item3_3.png';
import item34 from '../../../../images/environment/item3_4.png';
import item35 from '../../../../images/environment/item3_5.png';
import item36 from '../../../../images/environment/item3_6.png';

import close from '../../../../images/environment/close.png';
class EnvironmentPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      envirList: [
        { imgSrc: icon1, text: '绩效奖金' },
        { imgSrc: icon2, text: '专项奖金' },
        { imgSrc: icon3, text: '长期激励' },
        { imgSrc: icon4, text: '节日慰问' },
        { imgSrc: icon5, text: '足额缴纳五险一金' },
        { imgSrc: icon6, text: '满1年年假额外+1' },
        { imgSrc: icon7, text: '丰富的培训与沙龙' },
        { imgSrc: icon8, text: '完善的晋升发展空间' },
        { imgSrc: icon9, text: '5A级工作环境' },
        { imgSrc: icon10, text: '舒适的休息空间' },
        { imgSrc: icon11, text: '无限咖啡茶水' },
        { imgSrc: icon12, text: '国家级会议参与机会' },
      ],
      bigImgBox: [
        { id: 0, imgSrc: bigPic1 },
        { id: 1, imgSrc: bigPic2 },
        { id: 2, imgSrc: bigPic3 },
      ],
      smallImgBox: [
        { id: 0, imgSrc: bigPic1 },
        { id: 1, imgSrc: bigPic2 },
        { id: 2, imgSrc: bigPic3 },
      ],
      threeBox: [
        { id: 0, imgSrc: [item11, item12, item13, item14, item15, item16], text: '工作环境' },
        { id: 1, imgSrc: [item21, item22, item23, item24, item25], text: '培训/沙龙/活动' },
        { id: 2, imgSrc: [item31, item32, item33, item34, item35, item36], text: '荣誉/发展' },
      ],
      currentModalIndex: '',
      // overflowBody:true
      overflowModal: false,
    };
  }
  openOthers = (id) => {
    console.log(id, '选中图片ID');
    document.body.style.overflow = 'hidden';
    this.setState({
      currentModalIndex: id,
      overflowModal: true,
      overflowBody: false,
    });
  };

  closeModal = () => {
    document.body.style.overflow = 'auto';
    this.setState({
      currentModalIndex: '',
    });
  };

  render() {
    const { envirList, bigImgBox, currentModalIndex, threeBox, smallImgBox } = this.state;
    return (
      <div className={styles.environment_treat_box}>
        <div className={styles.environment_treat_title}>
          我们提供优厚的薪酬福利待遇、完善的社会保障、 良好的工作环境、以及快速成长的职业发展平台
        </div>
        <div className={styles.environment_treat}>
          <div className={styles.environment_treat_content}>
            {envirList &&
              envirList.map((item, index) => {
                return (
                  <div className={styles.envirTreatContent_item} key={index}>
                    <div className={styles.envirTreatContent_item_circle}>
                      <img src={item.imgSrc} alt="" />
                    </div>
                    <div className={styles.envirTreatContent_item_text}>{item.text}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className={styles.environment_treat_title_two}>成长发展在中电药明</div>
        {/* PC端 */}
        <div className={styles.threePic}>
          {bigImgBox &&
            bigImgBox.map((item, index) => {
              return (
                <div className={styles.threePic_item} key={index}>
                  <img
                    src={item.imgSrc}
                    alt=""
                    onClick={this.openOthers.bind(this, item.id)}
                    style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                  />
                </div>
              );
            })}
        </div>
        {/* 手机端 */}
        <div className={styles.threePic_small}>
          {smallImgBox &&
            smallImgBox.map((item, index) => {
              return (
                <div className={styles.threePic_item} key={index}>
                  <img src={item.imgSrc} alt="" onClick={this.openOthers.bind(this, item.id)} />
                </div>
              );
            })}
        </div>
        {/* 遮罩层 */}

        {currentModalIndex !== '' ? (
          <div className={styles.modal} style={{ overflow: this.state.overflowModal ? 'auto' : 'hidden' }}>
            <div className={styles.modal_content}>
              <img className={styles.modal_content_close} src={close} alt="" onClick={this.closeModal.bind(this)} />
              <div className={styles.modal_content_title}>
                {threeBox[currentModalIndex] && threeBox[currentModalIndex].text}
              </div>
              <div className={styles.modal_content_img}>
                {threeBox[currentModalIndex] &&
                  threeBox[currentModalIndex].imgSrc.map((item, index) => {
                    return <img src={item} alt="" key={index} />;
                  })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default EnvironmentPart;
