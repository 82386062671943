import React from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { Menu, Dropdown } from 'antd';

function Header(props) {
  // componentDidMount(){
  //   window.scroll(0, 0);
  // };
  const menu = (
    <Menu>
      <Menu.Item style={{ textAlign: 'center' }}>
        <a target="_blank" rel="noopener noreferrer" href="https://app.mokahr.com/campus-recruitment/cwdata/44641">
          校园招聘
        </a>
      </Menu.Item>

      <Menu.Item style={{ textAlign: 'center' }}>
        <a target="_blank" rel="noopener noreferrer" href="https://app.mokahr.com/social-recruitment/cwdata/44640">
          社会招聘
        </a>
      </Menu.Item>
    </Menu>
  );

  console.log(props.currentPage);
  return (
    <div className={classNames(styles.headerCmp)}>
      {/* 左侧 */}
      <div className={classNames(styles.headerLeft)} onClick={() => props.clickGoBack()}>
        {/* 左侧公司logo */}
        <div className={classNames(styles.headerLogo)} />
      </div>
      {/* 右侧 */}
      <div className={classNames(styles.headerRight)}>
        {/* 右侧按钮 */}
        <div className={classNames(styles.rightBtnBox)}>
          {/* <div
            style={{ color: props.currentPage === 'service' ? '#004EA2' : 'rgb(51, 51, 51)' }}
            className={classNames(styles.rightBtn)}
            onClick={() => props.clickHeaderService()}
          >
            产品服务
          </div> */}

          <Dropdown overlay={menu} placement="bottomCenter" overlayStyle={{ color: 'black' }}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <div
                style={{ color: props.currentPage === 'recruit' ? '#004EA2' : 'rgb(51, 51, 51)' }}
                className={classNames(styles.rightBtn)}
              >
                工作机会
              </div>
            </a>
          </Dropdown>
          {/* <div className={classNames(styles.rightBtn)} onClick={() => props.contactUs()}> */}
          {/* 联系我们 */}
          {/* </div> */}
        </div>
        {/* 右侧图标 */}
        <div className={classNames(styles.headerIconBox)} onClick={() => props.clickHeaderIcon()}>
          <div className={classNames(styles.headerIcon)} />
        </div>
      </div>
    </div>
  );
}

export default Header;
